import $ from "jquery";
import { Modal } from "bootstrap";
import fileInput from "@/view/pages/common/fileInput.vue";
import comment from "@/view/pages/common/comment.vue";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
  components: {
    fileInput,
    comment
  },
  computed: {
    oaEmpList: {
      get() {
        return this.$store.state.systemData.oaEmpList;
      }
    },
    taskList: {
      get() {
        return this.$store.state.taskStore.taskList;
      }
    },
    handEmpList: {
      get() {
        return this.$store.state.taskStore.handEmpList;
      }
    },
    user: {
      get() {
        return this.$store.getters.currentUser;
      }
    }
  },
  watch: {
    user: function() {
      this.initData();
    },
    oaEmpList: function() {
      if (this.oaEmpList && this.oaEmpList.length > 0) {
        if ($("#partIn").is(":visible")) {
          this.initSelect();
        } else {
          if (this.temp > 50) {
            this.temp = 0;
          }
          this.temp++;
          // 递归 等待dom渲染完毕
          var _this = this;
          setTimeout(function() {
            _this.initSelect();
          }, 500);
        }
      }
    },
    handEmpList: function() {
      if ($("#handOver").is(":visible")) {
        this.initSelectThree("handOver", this.handOverId, this.handEmpList);
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        var _this = this;
        setTimeout(function() {
          _this.initSelectThree(
            "handOver",
            _this.handOverId,
            _this.handEmpList
          );
        }, 500);
      }
    }
  },
  // created() {
  //   this.initData();
  // },
  mounted() {
    this.initData();
    this.getOrganizationList();
    this.delTaskT = new Modal(this.$refs.delTask);
    this.finTaskT = new Modal(this.$refs.finTask);
    this.editTaskT = new Modal(this.$refs.editTask);
  },
  data() {
    return {
      task: { principals: { recordId: "" } },
      editStatus: true,
      participateId: [],
      sharesId: [],
      temp: 0,
      principalsId: "",
      search: { architecture: "", architectureT: "", startTimeTask: "",endTimeTask: ""},
      fileList: [],
      recordId: null,
      handOverId: "",
      exportUrl: this.url + "/kybTask/export",
      report: "",
      postTask: {},
      taskCompletionList: [],
      taskSchedule: "",
      taskFlag: "2",
      organizationList: [],
      scoreMode: ""
    };
  },
  methods: {
    initSelect: function() {
      let option = "";
      option += "<option value=''>" + "请选择" + "</option>";
      this.oaEmpList.forEach(el => {
        option +=
          "<option value='" + el.recordId + "'>" + el.name + "</option>";
      });
      $("#partIn").empty();
      $("#partIn").append(option);
      $("#partIn").selectpicker("val", this.participateId);
      $("#partIn").selectpicker("render");
      $("#partIn").selectpicker("refresh");
      $("#partIn").selectpicker();

      $("#shareEmp").empty();
      $("#shareEmp").append(option);
      $("#shareEmp").selectpicker("val", this.sharesId);
      $("#shareEmp").selectpicker("render");
      $("#shareEmp").selectpicker("refresh");
      $("#shareEmp").selectpicker();

      $("#principalsEmp").empty();
      $("#principalsEmp").append(option);
      $("#principalsEmp").selectpicker("val", this.principalsId);
      $("#principalsEmp").selectpicker("render");
      $("#principalsEmp").selectpicker("refresh");
      $("#principalsEmp").selectpicker();
    },
    initData: function() {
      if (!this.recordId) {
        this.recordId = localStorage.getItem("recordId");
        window.localStorage.removeItem("recordId");
      }
      this.getDate();
      if (this.user && this.user.employeeId) {
        this.$store.dispatch("taskStore/getTaskHandOver", this.user);
        this.search = { condition: "", status: "1" };
        const emp = {};
        if (this.handOverId) {
          emp.recordId = this.handOverId;
        } else {
          emp.recordId = this.user.employeeId;
        }
        this.search.principals = emp;
        if (this.recordId) {
          this.search.recordId = this.recordId;
        } else {
          this.search.startTimeTask = $("#startTimeTask").val();
          this.search.endTimeTask = $("#endTimeTask").val();
          this.search.manage = this.user.manage;
        }
        this.$store.dispatch("taskStore/getTaskList", this.search);
        //弹窗结束
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
    searchTask: function() {
      this.search.recordId = null;
      this.search.manage = this.user.manage;
      this.search.architectureId = this.search.architecture;
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store.dispatch("taskStore/getTaskList", this.search);
      //弹窗结束
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    initDate: function(startDates) {
      if ($("#completeTime").is(":visible")) {
        $("#completeTime").daterangepicker(
          {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: false,
            timePicker24Hour: true,
            startDate: startDates, // 设置开始日期
            opens: "center",
            drops: "down",
            locale: {
              format: "YYYY-MM-DD",
              separator: " - ",
              applyLabel: "确定",
              cancelLabel: "取消",
              fromLabel: "From",
              toLabel: "到",
              customRangeLabel: "Custom",
              weekLabel: "W",
              daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
              monthNames: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
              ],
              firstDay: 1
            }
          },
          function() {}
        );
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function() {
          _this.initDate(startDates);
        }, 500);
      }
    },
    // 获取时间
    getDate: function() {
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      let startYear = startDate.getFullYear();
      let startMonth = startDate.getMonth() + 1;
      if (startMonth.length > 1) {
        startMonth = "0" + startDate.getMonth() + 1;
      } else {
        startMonth = startDate.getMonth() + 1;
      }
      let startDay =
        startDate.getDate() >= 10
          ? startDate.getDate()
          : "0" + startDate.getDate();
      let startTimeTask = startYear + "-" + startMonth + "-" + startDay;

      let endDate = new Date();
      let endYear = endDate.getFullYear();
      let endMonth = endDate.getMonth() + 1;
      endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
      let endDay =
        endDate.getDate() >= 10 ? endDate.getDate() : "0" + endDate.getDate();
      let endTimeTask = endYear + "-" + endMonth + "-" + endDay;

      this.initQueryDate("startTimeTask", startTimeTask);
      this.initQueryDate("endTimeTask", endTimeTask);
    },
    initQueryDate: function(id, startDates) {
      if ($("#" + id + "").is(":visible")) {
        const _this = this;
        $("#" + id + "").daterangepicker(
          {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: false,
            startDate: startDates, // 设置开始日期
            opens: "center",
            drops: "down",
            locale: {
              format: "YYYY-MM-DD",
              separator: " - ",
              applyLabel: "确定",
              cancelLabel: "取消",
              fromLabel: "From",
              toLabel: "到",
              customRangeLabel: "Custom",
              weekLabel: "W",
              daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
              monthNames: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
              ],
              firstDay: 1
            }
          },
          function(start) {
            if (id === "startTimeTask") {
              _this.search.startTimeTask = start.format("YYYY-MM-DD");
            } else if (id === "endTimeTask") {
              _this.search.endTimeTask = start.format("YYYY-MM-DD");
            }
            _this.search.manage = _this.user.manage;
              //弹窗开始
            _this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            _this.$store.dispatch("taskStore/getTaskList", _this.search);
            //弹窗结束
            _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        );
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function() {
          _this.initQueryDate(id, startDates);
        }, 500);
      }
    },
    editTask: function(item, num) {
      this.task = {};
      this.scoreMode = "";
      this.search.architectureT = "";
      this.getOrganizationList();
      this.editStatus = true;
      if (num === 2) {
        this.task = item;
        this.search.architectureT = item.architectureId;
        this.taskCompletion();
        //编辑查询任务进度
        this.getTaskSchedul(item);
      }
      this.getTaskDeail(item);
    },

    viewTask: function(item) {
      this.getOrganizationList();
      this.search.architectureT = item.architectureId;
      this.task = item;
      this.taskCompletion();
      this.editStatus = false;
      this.getTaskDeail(item);
    },
    getTaskDeail: function(item) {
      this.task = {};
      this.participateId = [];
      this.sharesId = [];
      this.principalsId = "";
      this.fileList = [];
      this.taskCompletionList = [];
      $("#file").val("");
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      if (item && item.recordId) {
        this.$axios
          .fetchPost("kybTask/getTask", item)
          .then(data => {
            if (data && data.data) {
              this.task = data.data;
              if (!data.data.scoreMode || data.data.scoreMode == undefined) {
                this.scoreMode = "";
              } else {
                this.scoreMode = data.data.scoreMode;
              }
              this.principalsId = this.task.principals.recordId;
              this.participateId = [];
              if (
                data.data.participateList &&
                data.data.participateList.length > 0
              ) {
                for (let i = 0; i < data.data.participateList.length; i++) {
                  this.participateId.push(
                    data.data.participateList[i].recordId
                  );
                }
              }
              this.sharesId = [];
              if (data.data.sharesList && data.data.sharesList.length > 0) {
                for (let i = 0; i < data.data.sharesList.length; i++) {
                  this.sharesId.push(data.data.sharesList[i].recordId);
                }
              }
              if (data.data.attachList) {
                this.fileList = data.data.attachList;
              }
              const depart = {};
              if (this.user.groupId) {
                const str = this.user.groupId.split(",");
                depart.recordId = str[0];
              }
              depart.workStatus = 1;
              this.$store.dispatch("systemData/loadOaEmpList", depart);
              this.initDate(this.task.completeTime);
              this.editTaskT.show();
            }
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.initDate();
        const depart = {};
        if (this.user.groupId) {
          const str = this.user.groupId.split(",");
          depart.recordId = str[0];
        }
        depart.workStatus = 1;
        this.$store.dispatch("systemData/loadOaEmpList", depart);
        this.editTaskT.show();
      }
      const kybReport = {};
      kybReport.employeeId = this.user.employeeId;
      this.$axios
        .fetchPost("kybReport/getSuperiorLeaders", kybReport)
        .then(data => {
          if (data && data.data) {
            this.report = data.data;
          }
        });
      this.editTaskT.show();
      //弹窗结束
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    delTask: function(item) {
      this.task = item;
      this.delTaskT.show();
    },
    deleteTask: function() {
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios
        .fetchPost("kybTask/deleteTask", this.task)
        .then(data => {
          if (data && data.data) {
            if (data.data === "success") {
              alert("删除成功");
            }
            this.delTaskT.hide();
            this.searchTask();
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    finTask: function(item) {
      this.task = item;
      this.finTaskT.show();
    },
    finshTask: function() {
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios
        .fetchPost("kybTask/finshTask", this.task)
        .then(data => {
          if (data && data.data) {
            if (data.data === "success") {
              alert("已完成");
            }
            this.finTaskT.hide();
            this.searchTask();
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTask: function(item) {
      this.taskFlag = item;
      this.search.createdBy = {};
      if (item === 1) {
        if (this.handOverId) {
          this.search.createdBy.recordId = this.handOverId;
        } else {
          this.search.createdBy.recordId = this.user.employeeId;
        }
      } else if (item === 2) {
        const emp = {};
        if (this.handOverId) {
          emp.recordId = this.handOverId;
        } else {
          emp.recordId = this.user.employeeId;
        }
        this.search.principals = emp;
      } else if (item === 3) {
        const emp = {};
        if (this.handOverId) {
          emp.recordId = this.handOverId;
        } else {
          emp.recordId = this.user.employeeId;
        }
        this.search.principals = emp;
      }
      this.search.number = item;
      this.searchTask();
    },
    initSelectThree: function(id, typeId, list) {
      $("#" + id).empty();
      let option = "";
      option += "<option value=''>" + "所有" + "</option>";
      list.forEach(el => {
        option +=
          "<option value='" + el.recordId + "'>" + el.name + "</option>";
      });
      $("#" + id).append(option);
      $("#" + id).selectpicker("val", typeId);
      $("#" + id).selectpicker("render");
      $("#" + id).selectpicker("refresh");
      $("#" + id).selectpicker();
      $("#" + id).selectpicker("val", typeId);
    },
    addTaskCompletion: function() {
      var flag = true;
      this.taskCompletionList.forEach(p => {
        if (!p.schedule) {
          alert("请先填写上一个的任务进度再添加");
          flag = false;
          return;
        }
        if (!p.score) {
          alert("请先填写上一个的分数再添加");
          flag = false;
          return;
        }
      });
      if (flag) {
        var obj = {};
        obj.schedule = "";
        obj.score = "";
        this.taskCompletionList.push(obj);
      }
    },
    taskCompletion: function() {
      if (this.task && this.task.recordId) {
        //弹窗开始
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        const query = {};
        if (this.task.postTaskId) {
          query.postTaskId = this.task.postTaskId;
        } else {
          query.postTaskId = this.task.recordId;
        }
        const _this = this;
        this.$axios
          .fetchPost("kybTask/getTaskCompletion", query)
          .then(data => {
            if (data && data.data) {
              _this.taskCompletionList = data.data;
            }
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    delTaskCompletion: function(index) {
      if (this.taskCompletionList[index].recordId) {
        //弹窗开始
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        const query = {};
        query.recordId = this.taskCompletionList[index].recordId;
        const _this = this;
        this.$axios
          .fetchPost("kybTask/delTaskCompletion", query)
          .then(data => {
            if (data.data === "success") {
              alert("删除成功");
              _this.taskCompletionList.splice(index, 1);
            }
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.taskCompletionList.splice(index, 1);
      }
    },
    saveTaskCompletion: function() {
      if (this.taskCompletionList.length <= 0) {
        alert("未填写任务评分数据！");
        return;
      }
      if (this.taskCompletionList.length > 0) {
        for (let i = 0; i < this.taskCompletionList.length; i++) {
          this.taskCompletionList[i].postTaskId = this.task.recordId;
        }
        const query = {};
        query.taskCompletionList = this.taskCompletionList;
        //弹窗开始
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        const _this = this;
        this.$axios
          .fetchPost("kybTask/saveTaskCompletion", query)
          .then(data => {
            if (data.data === "success") {
              alert("保存成功！");
            }
            _this.taskCompletion();
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    getTaskSchedul(item) {
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios
        .fetchPost("kybTask/getTaskSchedul", item)
        .then(data => {
          this.taskSchedule = data.data;
          //弹窗结束
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkVolumeRatio: function() {
      // 将输入值转换为浮点数
      let inputNumber = parseFloat(this.task.volumeRatio);

      // 检查是否为正数且不大于100
      if (inputNumber < 0 || inputNumber > 100 || isNaN(inputNumber)) {
        // 如果输入不符合要求，将输入值置为空字符串
        this.task.volumeRatio = "";
      }
    },
    setFileList: function (data) {
      this.fileList = data;
    },
    saveTask: function() {
      this.user.recordId = this.user.employeeId;
      this.task.createdBy = this.user;
      if (!this.task.content) {
        alert("请填写任务内容");
        return;
      }
      if (!this.task.emergencyLevel) {
        alert("请选择紧急程度");
        return;
      }
      if (!this.principalsId) {

        alert("请选择负责人");
        return;
      } else {
        this.task.principals = {};
        this.task.principals.recordId = this.principalsId;
      }
      if (this.task.schedule === null || this.task.schedule === "") {
        alert("请填写任务进度");
        return;
      }
      this.task.completeTime = $("#completeTime").val();
      if (!this.task.completeTime) {
        alert("请填写到期日期");
        return;
      }
      if (!this.task.taskType) {
        alert("请选择任务周期");
        return;
      }
      if (!this.task.requireStatus) {
        alert("请选择任务类型");
        return;
      }
      if (!this.task.volumeRatio) {
        alert("请填写任务量比");
        return;
      }
      this.task.participateList = [];
      if (this.participateId) {
        for (let i = 0; i < this.participateId.length; i++) {
          const emp = {};
          emp.recordId = this.participateId[i];
          if (emp.recordId !== "") {
            this.task.participateList.push(emp);
          }
        }
      }
      this.task.sharesList = [];
      if (this.sharesId) {
        for (let i = 0; i < this.sharesId.length; i++) {
          const emp = {};
          emp.recordId = this.sharesId[i];
          if (emp.recordId !== "") {
            this.task.sharesList.push(emp);
          }
        }
      }
      this.task.architectureId = this.search.architectureT;
      this.task.scoreMode = this.scoreMode
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // 通过form表单进行提交
      const param = new FormData();
      param.append("task", JSON.stringify(this.task));
      if (this.fileList && this.fileList.length > 0) {
        for (let i = 0; i < this.fileList.length; i++) {
          if (!this.fileList[i].downloadUrl) {
            param.append("files", this.fileList[i]);
          }
        }
      }
      this.$axios
        .formPost("kybTask/saveTask", param)
        .then(data => {
          if (data.data === "success") {
            alert("提交成功");
            this.editTaskT.hide();
            this.searchTask();
          } else if (data.data === "false") {
            alert("文件上传失败");
            this.editTaskT.hide();
            this.searchTask();
          } else {
            alert("系统错误");
          }
          //弹窗结束
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        })
        .catch(err => {
          console.log(err);
        });
    },
    getOrganizationList() {
      this.$axios
        .fetchPost(
          "hr/recruitment/getOrganizationList",
          {}
        )
        .then(result => {
          this.organizationList = result.data;
          this.handleSelectValueTwo();
          this.handleSelectValueT();
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSelectValueTwo() {
      $("#architectureId").empty();
      let option = "";
      option += "<option value=''>" + "请选择" + "</option>";
      this.organizationList.forEach(el => {
        option +=
          "<option value='" +
          el.recordId +
          "'>" +
          el.departmentName +
          "</option>";
      });
      $("#architectureId").append(option);
      $("#architectureId").selectpicker("val", this.search.architecture);
      $("#architectureId").selectpicker("render");
      $("#architectureId").selectpicker("refresh");
      $("#architectureId").selectpicker();
    },
    handleSelectValueT() {
      $("#architectureIdT").empty();
      let option = "";
      option += "<option value=''>" + "请选择" + "</option>";
      this.organizationList.forEach(el => {
        option +=
          "<option value='" +
          el.recordId +
          "'>" +
          el.departmentName +
          "</option>";
      });
      $("#architectureIdT").append(option);
      $("#architectureIdT").selectpicker("val", this.search.architectureT);
      $("#architectureIdT").selectpicker("render");
      $("#architectureIdT").selectpicker("refresh");
      $("#architectureIdT").selectpicker();
    }
  }
};
